export const unFlattenResults = (results) => {
    results.map(post => {
        //console.log(post)
        const { title, slug, date, tags, author, category } = post
        
        const newResults = { 
            title: title,
            slug: slug,
            date: date,
            tags: tags,
            author:{
                name: author
            },
            category:{
                name: category
            }
         }
         console.log(newResults)
        return newResults
    })
}

export const getRandomFromArray = (array, itemAmount) => {
    do {
        let randomNumber = Math.floor((Math.random() * array.length))
        if(array[randomNumber])
            array.splice(randomNumber,1)
      } while (array.length > itemAmount)
      return array
}

export const getPagination = (array, itemsPerPage, pageNumber) => {
    return array.slice((pageNumber-1)*itemsPerPage, ((pageNumber-1)*itemsPerPage)+itemsPerPage)
}

export const getDateSorted = (data) => {
    const sorter = (a, b) => {
        return new Date(b.node?.date) - new Date(a.node?.date)
    }
    data.sort(sorter)
    return data
}

export const findNextChapter = (story, chapter) => {
    let nextChapter = false
    story.chapters.sort((a,b)=>{
        return a.number - b.number
    })
    story.chapters.map((storyChapter, index)=>{
        if ((storyChapter.slug === chapter.slug)&&(story.chapters.length > index + 1)){
            nextChapter = story.chapters[index+1]
        }
        return nextChapter
    })
    return nextChapter
}

export const getRelatedFromArray = (category,tags,array) => {
    const tagSlugs = tags.map((tag)=>tag.slug)
    let related = []
    array.forEach(post => {
        const postTagSlugs = post.node.tags.map((tag)=>tag.slug)
        const postCategory = post.node.category.slug
        if ((tagSlugs.some((item) => postTagSlugs.includes(item)))||(category===postCategory))
        {
            related.push(post)
        }
    })
    return related
}