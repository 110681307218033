import React from "react"
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import { Link} from "gatsby"
import FeaturedImage from "./featured-image"

const PostGrid = ({posts}) => {

    return (
        <>
        {posts.map((post) => {
            let slugString = ""
            if (post.node.id.includes("Posts")) slugString ="posts"
            if (post.node.id.includes("Stories")) slugString ="stories"
            return(
                <Col id="post-card-col" sm="auto" md={6} lg={4} key = {"col-" + post.node.slug}>
                <Card id = "post-card" key = {"card-" + post.node.slug}>
                    <Link to={`/${slugString}/${post.node.slug}`} className="uk-link-reset">
                        <FeaturedImage alt={post.node.title || ""} image={post.node.image || ""} />
                    </Link>
                    <Card.Body>
                        <Card.Title><Link to={`/${slugString}/${post.node.slug}`}>{post.node.title}</Link></Card.Title>
                        <Card.Text>
                            <p>
                            <i class="fas fa-user" id="card-icon"></i>{` `}<Link to={`/authors/${post.node.author.slug}`}>{post.node.author.name}</Link> {`  `}
                            <i class="fas fa-folder" id="card-icon"></i>{` `}<Link to={`/categories/${post.node.category.slug}`}>{post.node.category.name}</Link> {`  `}
                            <i class="fas fa-tag" id="card-icon"></i>{` `}{post.node.tags?.map((tag)=>(
                            <><Link to={`/tags/${tag.slug}`}>{tag.name}</Link>{`  `}</>
                            ))}
                            </p>
                        </Card.Text>
                    </Card.Body>
                </Card>
                </Col>

            )
        }
            )}
        </>
        
    )
}
export default PostGrid