import React from "react"
import { graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Menu from '../components/menu'
import Footer from '../components/footer'
import Seo from '../components/seo'
import PostsGrid from '../components/posts-grid'
import '../styles/main.css'
import {getRandomFromArray} from '../utils/utils'


const IndexPage = ({data}) => {
  const posts = data.allStrapiPosts.edges
  const stories = data.allStrapiStories.edges
  const itemsPerPage = 9
  const postAndStories = getRandomFromArray(posts.concat(stories),itemsPerPage)
  //console.log(postAndStories)
  return (
    <>
      <Seo/>
      <Container fluid="md">
        <Row>
          <Col>
            <Menu/>
          </Col>
        </Row>
        <Row id="content-row">
        <Col>
        <Row>
          <PostsGrid posts={postAndStories} />
        </Row>

        </Col>
        </Row>
        <Footer/>

      </Container>
    </>
  )
}

export const query = graphql`
  query {
    allStrapiPosts (sort: {fields: date, order: DESC}){
      edges{
          node{
            id
            title
            slug
            date
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            author {
              name
              slug
            }
            category {
              name
              slug
            }
            tags {
              name
              slug
            }
          }
      }
    }
    allStrapiStories (sort: {fields: date, order: DESC}){
      edges{
          node{
            id
            title
            slug
            date
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            author {
              name
              slug
            }
            category {
              name
              slug
            }
            tags {
              name
              slug
            }
          }
      }
    }
  }
`

export default IndexPage
/*
            
*/